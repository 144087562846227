<template>
    <div class="container">
        <div class="content">
            <div class="text">
                <div>车场名称</div>
                <div>{{parkingName}}</div>
            </div>
            <div class="text">
                <div>道口名称</div>
                <div>{{name}}</div>
            </div>
            <div class="text">
                <div>道口类型</div>
                <div>{{type}}</div>
            </div>
            <div class="text">
                <div>道口设备状态</div>
                <div>{{status}}</div>
            </div>
        </div>
        <div id="localStreamContainer"></div>
        <div id="remoteStreamContainer"></div>
        <div class="loading" v-show="show">
            <div class="loading-img">
                <img src="../assets/img/loading.png" class="rotate-img">
            </div>
            <div class="loading-text">等待接听中...</div>
        </div>
        <div class="loading" v-show="hide">
            <div class="loading-text">正在通话中</div>
        </div>
        <div class="bottom-btns">
            <div class="btn-hangup" @click="hangUp">
                <img class="btn-image" src="../assets/img/hangup.png"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {Button, Dialog} from 'vant';
    import TRTC from 'trtc-js-sdk';
    import {handUpNotice, signUser, uattendedInfo, uattendedNotice} from "../api/interface"

    export default {
        name: "abnormalCall",
        components: {
            [Button.name]: Button,
        },

        data() {
            return {
                parkingName: "",
                name: "",
                type: "",
                status: "",
                roomId: "",
                userId: "",
                client: '', // 客户端服务
                remoteStream: '', // 远方播放流
                localStream: '', // 本地流
                remoteStreamList: [], // 远端小视频
                localStream: "",
                show: true,
                hide: false
            }
        },
        mounted() {
            this.getSignUser();
            this.getUattendedInfo();
            this.getUattendedNotice();
        },
        methods: {
            getUattendedNotice() {
                var roomId = this.$route.query.roomID;
                var userID = this.$route.query.userID;
                uattendedNotice(roomId, userID).then(res => {
                    if (res.data.data) {
                        Dialog.alert({
                            title: '温馨提示',
                            message: '语音通话次数已用完，无法发起通话，请联系管理员，联系电话：' + res.data.data,
                            confirmButtonColor: '#DC5040'
                        }).then(() => {
                            // on close
                            window.location.href = 'tel://' + res.data.data;
                        });
                    }
                })
            },
            getUattendedInfo() {
                var roomId = this.$route.query.roomID;
                uattendedInfo(roomId).then(res => {
                    this.parkingName = res.data.data.parkingVO.parkingName;
                    this.name = res.data.data.parkingRoadVO.name;
                    this.type = res.data.data.parkingRoadVO.type === 1 ? '入口' : res.data.data.parkingRoadVO.type === 2 ? '出口' : ' ';
                    this.status = res.data.data.equipmentVO.status === 1 ? '在线' : res.data.data.equipmentVO.status === 2 ? '离线' : ' ';
                })
            },
            getSignUser() {
                var userID = this.$route.query.userID;
                signUser(userID).then(res => {
                    this.createClient(res.data.data);
                })
            },
            // 可以绑定在按钮上，点击按钮，进行视频通话
            async createClient(userSig) {
                this.userId = this.$route.query.userID;
                this.roomId = this.$route.query.roomID;
                let roomId = parseInt(this.$route.query.roomID);
                // 获取签名
                let sdkAppId;
                if (process.env.NODE_ENV === "production") {
                    sdkAppId = 1400620116;
                } else {
                    sdkAppId = 1400577312;
                }
                const userId = this.userId;
                this.client = TRTC.createClient({
                    mode: 'rtc', // 实时音视频通话
                    sdkAppId,
                    userId,
                    userSig
                })
                // 1.监听事件
                this.client.on('stream-added', event => {
                    const remoteStream = event.stream;
                    console.log('远端流增加: ' + remoteStream.getId());
                    //订阅远端流
                    this.client.subscribe(remoteStream);
                });
                this.client.on('stream-subscribed', event => {
                    // 远端流订阅成功
                    const remoteStream = event.stream;
                    // 播放远端流，传入的元素 ID 必须是页面里存在的 div 元素
                    remoteStream.play('remoteStreamContainer');
                });
                this.client.on('peer-join', event => {
                    this.show = false;
                    this.hide = true;
                });
                this.client.on('peer-leave', event => {
                    this.hangUp();
                });
                try {
                    await this.client.join({roomId})
                        .catch(error => {
                            console.error('进房失败 ' + error)
                        })
                        .then(() => {
                            console.log('进房成功')
                            // 创建本地流
                        })
                    this.localStream = TRTC.createStream({userId, audio: true, video: false});
                    await this.localStream.initialize().catch((error) => {
                        switch (error.name) {
                            case 'NotAllowedError':
                                // 提示用户：不授权摄像头/麦克风访问无法进行音视频通话
                                const audioTrack = this.localStream.getAudioTrack();
                                this.localStream.replaceTrack(audioTrack);
                                break;
                            case 'NotReadableError':
                                // 提示用户：暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试。
                                console.error('暂时无法访问摄像头/麦克风');
                                break;
                            case 'RtcError':
                                // DEVICE_NOT_FOUND
                                if (error.getCode() === 4099) {
                                    // 当前设备没有麦克风或没有摄像头，但尝试采集麦克风、摄像头。
                                    // 处理建议：引导用户检查设备的摄像头及麦克风是否正常，业务侧应在进房前的进行设备检测。
                                    console.error('当前设备没有麦克风或没有摄像头，但尝试采集麦克风、摄像头');
                                }
                                break;
                            default:
                                // alert(error)
                                console.error(error);
                                break;
                        }
                    });
                    // 播放本地流
                    this.localStream.play("localStreamContainer");
                    // await this.client.publish(this.localStream);
                    await this.client
                        .publish(this.localStream)
                        .catch(error => {
                            console.error('本地流发布失败 ' + error)
                        })
                        .then(() => {
                            console.log('本地流发布成功')
                        })
                } catch (error) {
                    console.error(error);
                    // alert('error ' + error)
                }
            },
            // 退出房间
            hangUp() {
                var roomId = this.$route.query.roomID;
                var userID = this.$route.query.userID;
                handUpNotice(roomId, userID).then(res => {
                    this.leaveRoom();
                })
            },
            async leaveRoom() {
                this.localStream.close();
                await this.client.leave();
                // 退房成功，如果没有调用 client.destroy()，可再次调用 client.join 重新进房开启新的通话
                // 调用 destroy() 结束当前 client 的生命周期
                this.client.destroy();
                setTimeout(function () {
                    document.addEventListener(
                        'WeixinJSBridgeReady',
                        function () {
                            this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
                        },
                        false
                    )
                    this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
                }, 300);
                //以下是普通浏览器关闭代码
                window.opener = null;
                window.open('about:blank', '_top').close();
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#000')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style scoped>
    .content {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        text-align: left;
        line-height: 50px;
        margin: 10px;
    }

    .content .text {
        display: flex;
        justify-content: space-between;
        color: white;
        font-size: 16px;
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        /*width: 300px;*/
        /*height: 250px;*/
        border-radius: 12px;
        /*background: rgba(0, 0, 0, 0.6);*/
        color: white;
        /*padding: 40px;*/
        display: flex;
        flex-direction: column;
    }

    .loading-img {
        display: flex;
        justify-content: center;
        align-items: center;
        animation: rotate 2s linear infinite;
    }

    .loading-text {
        width: 100%;
        font-size: 20px;
        padding-top: 40px;
        text-align: center;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .template-1v1 .handle-btns {
        position: absolute;
        z-index: 3;
        bottom: 15vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .bottom-btns {
        position: absolute;
        z-index: 3;
        bottom: 3vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .btn-image {
        width: 5vh;
        height: 5vh;
    }

    .btn-hangup {
        width: 8vh;
        height: 8vh;
        background: #f75c45;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: fixed;
        bottom: 2vh;
    }

</style>
